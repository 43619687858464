import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type options = {
  label?: string,
  value?: number
}[]

type AuthState = {
  isAuthenticated: boolean;
  companyID: string;
  paymentTypeArray: options;
  swalRedirect: boolean;
  selectedAccountCount:number;
};

const initialState: AuthState = {
  isAuthenticated: false,
  companyID: '',
  paymentTypeArray: [],
  swalRedirect: false,
  selectedAccountCount:0
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    handleLogIn: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    redirectCompany: (state, action: PayloadAction<string>) => {
      state.companyID = action.payload
    },
    getPaymentType: (state, action: PayloadAction<options>) => {
      state.paymentTypeArray = action.payload
    },
    handleSwalRedirect: (state, action: PayloadAction<boolean>) => {
      state.swalRedirect = action.payload
    },
    handleselectedAccountCount: (state, action: PayloadAction<number>) => {
      state.selectedAccountCount = action.payload
    }
  },
});

export default auth.reducer;

// Actions
export const { logOut, handleLogIn, redirectCompany, getPaymentType, handleSwalRedirect,handleselectedAccountCount } = auth.actions;

